import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalGuard {

  constructor(
    private readonly router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve) => {
      // Access additional data passed with navigation
      const isInternalNavigation = this.router.getCurrentNavigation()?.extras.state?.isInternalNavigation;
      // If the route was not navigated to programmatically, redirect
      if (!isInternalNavigation) {
        this.router.navigate(['/signin']); // Navigate away to signin page
        resolve(false);
      } else {
        // If the route was navigated to programmatically, allow access
        resolve(true);
      }
    });
  }
}
