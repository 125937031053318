import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';
import { InternalGuard } from './guards/internal.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome', // TODO: Set this to ''
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/secure/secure.module').then(m => m.SecureModule),
    canActivate: [AuthGuard] // Secure all child pages
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/public/signin/signin.module').then(m => m.SigninPageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'two-factor',
    loadChildren: () => import('./pages/public/two-factor/two-factor.module').then(m => m.TwoFactorPageModule),
    canActivate: [PublicGuard, InternalGuard] // Prevent for signed in users and allow only when accessed internally
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/public/privacy/privacy.module').then(m => m.PrivacyPageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/public/terms/terms.module').then(m => m.TermsPageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/public/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset-request',
    loadChildren: () => import('./pages/public/password-reset-request/password-reset-request.module').then( m => m.PasswordResetRequestPageModule),
    canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then(m => m.PasswordResetPageModule),
    canActivate: [PublicGuard] // Prevent for signed-in users
  }  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
