import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../services/auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  async canActivate(): Promise<boolean> {
    // Get the Observable of authentication status
    const isAuthenticated$ = this.authService.isAuthenticated();
  
    // Wrap the Observable in a Promise
    return new Promise<boolean>((resolve) => {
      // Subscribe to the Observable to get the authentication status
      isAuthenticated$.subscribe((isAuthenticated: boolean) => {
        // Process the authentication status
        if (!isAuthenticated) {
          // If not authenticated, allow activation of the route
          resolve(true);
        } else {
          // If authenticated, navigate to the home page and disallow activation of the route
          this.router.navigate(['/home']);
          resolve(false);
        }
      });
    });
  }
  
}
